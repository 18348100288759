<script setup lang="ts">
import { Data } from '../stores/data';
var def=defineProps({
    backText: {
    type: [String, Object],
  },
});

let imgUrl =Data.userInfo.value || 'https://ikeben.cn/chat/web/public/img/my-head.png';
</script>

<template>
    <div class="mess-ctr">
        <div class="text-ctr" >
             <!-- 这里不用能 v-html，因为如果用 v-html输入html标签会被转化 -->
            <p v-if="typeof backText === 'string' || !backText?.isHtml">{{ backText }}</p>
            <p  v-html="(backText as any).text" ></p>          
        </div>
         <div class="cro">
                    <div class="cro_left_top"></div>
            </div>
        <div class="avatar">
            <img class="rounded-circle" :src="imgUrl" alt="avatar">
        </div>
       
    </div>
</template>

<style scoped>
.cro{
    transform: rotate(180deg);
    background-color: #FFDE9C;
}
.mess-ctr{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0.02rem 0 0.14rem 10%;
    overflow: hidden;
    -webkit-animation: msgRight .5s ease-in-out;
    animation: msgRight .5s ease-in-out;
}
.rounded-circle{
    display: block;
}

.avatar{
    min-width: 0.35rem;
    min-height: 0.35rem;
    border-radius: 0.3rem;
    overflow: hidden;
    
    height: 0.35rem;
    width: 0.35rem;
    flex-shrink: 0;
}
.text-ctr{
    white-space: pre-wrap;
    background: #FFDE9C;
    border-radius: 20px 0px 20px 20px;
    padding: 0.06rem 0.12rem;
    max-width: calc(100% - 0.35rem);
    box-sizing: border-box;
    /* margin-right: 0.06rem; */
    line-height: 0.24rem;
    overflow: hidden;
}

</style>

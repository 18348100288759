import axios, { type AxiosInstance, type AxiosRequestConfig} from 'axios';
import { Data } from '@/stores/data';
import { ElementTip } from '../../stores/ElementTip'
import { Utill } from '../../stores/Utill';
/**
 * http网络请求基类
 */
export  class BaseRequest {
    protected instance: AxiosInstance;
    protected config: AxiosRequestConfig;

    constructor(config?: AxiosRequestConfig) {
        this.config = config || {};
        this.instance = axios.create(config);
        this.setRequestInterceptor();
        this.setResponseInterceptor();
    }

    /**
     * 设置请求拦截器
     */
    protected setRequestInterceptor() {
        this.instance.interceptors.request.use(
            (opts) => {
                console.log("请求,参数-->",opts);
                return opts;
            },
            (err) => {
                console.log("请求,错误-->",err);
                return Promise.reject(err);
            },
        );
    }

    /**
     * 设置响应拦截器
     */
    protected setResponseInterceptor() {
        this.instance.interceptors.response.use(
            (response) => {
              if(response.data.code==200){
                return response.data;
              }else if(response.data.code==401){
                // let userToken=localStorage.getItem('userToken');
                // ElementTip.errorTip('登录超时，重新登录')
                Utill.shachPage();
                return Promise.reject(response.data);
                // window.location.reload();

              }
              else{
                let errTip=response.data.msg?response.data.msg:'网络错误,请稍候再试'
                ElementTip.errorTip(errTip)
                // return Promise.reject(response.data.code);
              }
                console.log("请求成功,返回-->",response);
               
            },
            (err) => {
                ElementTip.errorTip('网络错误,请稍候再试')
                console.log("请求失败,返回-->",err);
                return Promise.reject(err);
            },
        );
    }

   request<T = any>(config: AxiosRequestConfig): Promise<T> {
        return this.instance(config);
      }


}

//消息初始数据
 const badgeConfig: AxiosRequestConfig = {
    baseURL: import.meta.env.VITE_BASE_URL+'api/baihong/',
    // baseURL: 'https://ikeben.cn/',
    method: 'POST',
    timeout: 15000,
    headers: {
        'Content-Type': 'text/event-stream',//'application/x-www-form-urlencoded;charset=utf-8', //x-www-form-urlencoded  application/json
        'X-Requested-With': 'XMLHttpRequest',
        // "Accept":"text/event-stream",
        "token":Data.userToken.value
    },
    withCredentials: false,
 };

// 创建全局的Axios实例
export  const axiosApi: BaseRequest = new BaseRequest(badgeConfig);





import  {ElMessage}  from 'element-plus'
// import { ElMessage, ElMessageBox } from 'element-plus'
// import type { Action } from 'element-plus'
export class ElementTip { 
    static successTip(str: string,duration:number=1000) {
        ElMessage({
          message: str,
            type: 'success',
            duration,
            grouping:true,
        })
      }
      static errorTip (str:string,duration:number=1000) {
        ElMessage({
            message: str,
              type: 'error',
              duration,
              grouping:true,
          })
      }
      static warningTip (str:string,duration:number=1000) {
        ElMessage({
            message: str,
              type: 'warning',
              duration,
              grouping:true,
          })
      }
      static tipPage(){
        // ElMessageBox.confirm(
        //   'proxy will permanently delete the file. Continue?',
        //   'Warning',
        //   {
        //     confirmButtonText: 'OK',
        //     cancelButtonText: 'Cancel',
        //     type: 'warning',
        //   }
        // )
        //   .then(() => {
        //     ElMessage({
        //       type: 'success',
        //       message: 'Delete completed',
        //     })
        //   })
        //   .catch(() => {
        //     ElMessage({
        //       type: 'info',
        //       message: 'Delete canceled',
        //     })
        //   })
        console.log("chisshi>>>>>>>")
        // ElMessageBox.alert('登录超时，重新登录', '提示', {
        //   confirmButtonText: '确定',
        //   callback: () => {
        //     const urlWithoutParams = window.location.href.split('?')[0]
        //     location.replace(urlWithoutParams)
        //   },
        // })
      }
}




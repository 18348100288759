<script setup lang="ts">
import { ref, watch ,isRef,unref,toRaw} from 'vue'
const $emit = defineEmits([
  'stopMassage',
  'sendQuestion',
])
let def = defineProps({
  isSending: {
    type: Boolean,
    default: false,
  },
  isStartSteam: {
    type: Boolean,
    default: false,
  },
})
var sendText = ref() //输入框文字

//获取输入框的默认显示文字
function getInputPlaceholder() {
  return "请输入您的具体问题，越清楚越好～"
}
//暂停回答
function stopMassage() {
  $emit('stopMassage')
}
//点击发送按钮
function clickSend() {
  if (sendText.value) {
    $emit('sendQuestion', sendText.value)
    sendText.value = ''   
  }
}

function isShowSend() {
  if (sendText.value && sendText.value.length > 0) {
      return true
    }
  return false
}

</script>
<template>
  <div class="composer">


    <div class="composer-inputWrap">

      <div class="el-input">
        <el-input
          v-model="sendText"
          :autosize="{ minRows: 1, maxRows: 4 }"
          type="textarea"
          :placeholder="getInputPlaceholder()"
          :disabled="isSending"
        />
      </div>
    </div>
    <div
      class="Composer-actions"
      data-action-icon="paper-plane"
      v-show="isStartSteam || isShowSend()"
    >
      <button
        class="IconBtn"
        type="button"
        @click="clickSend"
        v-if="!isStartSteam"
      >
        <img class="Icon" src="../../assets/send2.svg" />
      </button>
      <button class="IconBtn" type="button" @click="stopMassage" v-else>
        <img class="Icon" src="../../assets/stop.svg" />
      </button>
    </div>
  </div>
</template>
<style scoped>
.composer {
  -ms-flex-align: end;
  align-items: flex-end;
  display: -ms-flexbox;
  display: flex;
  padding: 0.08rem;
  position: relative;
}

.lw-tip-ctr {
  position: absolute;
  z-index: 1;
  top: -0.35rem;
  color: #fff;
}
.tip-body {
  border-radius: 8px;
  opacity: 1;
  background: #3b3b3b;
  padding: 0.08rem;
  display: flex;
  align-items: center;
}
.tip-body::before {
  content: ' ';
  position: absolute;
  border: 0.09rem solid transparent;
  width: 0;
  height: 0;
  border-bottom-color: #3b3b3b;
  left: 0.53rem;
  bottom: -0.16rem;
  rotate: 180;
  transform: rotate(180deg);
}

.composer-inputWrap {
  flex: 1;
  position: relative;
  min-width: 0;
  display: flex;
  flex-wrap: nowrap;
  background: #fff;
  border-radius: 0.1rem;
  overflow: hidden;
  /* height: 37px; */
  align-items: flex-start;
}
.lw-ctr {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 0.09rem;
  gap: 0.1rem;
  padding-left: 0.1rem;
}
.lw-ico {
  width: 0.18rem;
  height: 0.18rem;
}
.fengexian {
  width: 1px;
  background: #ccc;
  height: 0.2rem;
}
.el-input {
  flex: 1;
}

.Composer-actions {
  margin-left: 8px;
  flex-shrink: 0;
  width: 0.35rem;
  height: 0.35rem;
}

.Composer-actions .IconBtn {
  background: #59adec;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 0;
  cursor: pointer;
  padding: 0;
}

.Icon {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>

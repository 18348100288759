
import { axiosApi } from './requests/BaseRequest';
import type {imgDataType} from '../stores/typeInterface'
import {Data} from '../stores/data'
export class HTTP {


     /**
   * post 获取消息接口，主要传递参数
   * @param coursewareId
   * @returns
   */
    static newCoursewareDetail(coursewareId: string) {
        // console.log(">>>import.meta.env.VITE_BASE_URL>>>",import.meta.env.VITE_BASE_URL)
    return  axiosApi.request({
      // url:'https://ikeben.cn/proxy/aigc/t/',
        // url: 'https://ikeben.cn/emb/query.php',
        //emb/newsQuery.php
        url:'emb/newsQuery.php',
        // url:'emb/newsQuerytest.php',
        timeout: 60000,
        data: {
            openid:window.xh_wen_data.openid,
            s:window.xh_wen_data.coursewareDetail.s,
            t:window.xh_wen_data.coursewareDetail.t,
            tmp:window.xh_wen_data.coursewareDetail.tmp,
            q: coursewareId,
        },
    });
  }
    /**
   * get获取卡片数据
   * @returns
   */
  static getInitData() {


    // return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //               resolve({
    //                 type: 3,
    //                 content: {
    //                   text: '<h4>Hi，我是你的AI助教~</h4><p>你想问关于《热榜打卡题》哪个学科的问题呢？</p>',
    //                   btns:['数学', '历史', '物理']
    //                 },
    //               }  )
    //         }, 100);
          
    //     })
    return  axiosApi.request({
      headers: {
        "token":Data.userToken.value
      },
        // url: 'https://ikeben.cn/emb/xiaoguangapi.php',
        url:'/userinfo/getStatic',
        method: 'GET',
    });
  }

   /**
   * 获取用户信息
   * @returns
   */
   static getUserInfo() {

    return axiosApi.request({
      headers: {
        "token":Data.userToken.value
      },
      url: '/userinfo/get',
      method: 'GET',
      
   });
  }
   /**
   * 获取token
   * @returns
   */
   static getToken(code:string) {

    return axiosApi.request({
      url: '/userinfo/wxLogin',
      method: 'GET',
      params: {
        code,
      },
      
   });
  }
   /**
   * 设置当前学科
   * @returns
   */
   static setSubjectName(subjectName:string) {

    return axiosApi.request({
      headers: {
        "token":Data.userToken.value
      },
      url: '/setSubject',
      method: 'GET',
      params: {
        subject:subjectName,
      },
      
   });
  }
  //
  // static setSubjectName(subjectName:string){
  //   return axiosApi.request({
  //     url: '/setSubject',
  //     data: {
  //       subjectName
  //   },
      
  //  });
  // }

}



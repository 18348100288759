import './css/main.css'

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import "./rem.js"
import 'element-plus/theme-chalk/el-message.css';

import katex from 'katex'
// // !import 不能忘记引入katex的样式
import 'katex/dist/katex.css'
// 引入katex下的自动渲染函数
import renderMathInElement from 'katex/contrib/auto-render/auto-render'

import { ElementTip } from './stores/ElementTip'
const app = createApp(App)


const env = import.meta.env
console.log("lhg>>>",env)

// 定义自动渲染的配置参数
const renderOption = {
  delimiters: [
    {left: '$$', right: '$$', display: true},
    {left: '$', right: '$', display: false},
    {left: '\\(', right: '\\)', display: false},
    {left: '\\[', right: '\\]', display: true}
  ],
    throwOnError: false,
    maxSize:100,
}

app.config.errorHandler = (err) => {
    console.error('main捕获到错误:', err);
     ElementTip.warningTip('发生一个错误，如影响使用，请刷新试试',3000)
  // 在这里可以进行错误上报、记录日志或其他逻辑处理
};

function renderMath(dom: any) {
    if (dom) {
        renderMathInElement(dom, renderOption)
    }    
}

app.config.globalProperties.$renderMath = renderMath;


// app.use(router)

app.mount('#app')

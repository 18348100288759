import type { compositionType, imgDataType } from '../stores/typeInterface'
import { HTTP } from '../network/http'
import { ElementTip } from '../stores/ElementTip'
import { Data } from './data'
import { ElMessageBox } from 'element-plus'
// import { downList, type downListKey } from './StaticText'

export class Utill {
  /**
   * 时间补0
   */
  static addZero(value: number): string {
    return value < 10 ? '0' + value : '' + value
  }
  /**
   * 格式化日期 默认格式 yyyy-MM-dd hh:mm:ss
   * @param date unit ms | 日期对象
   * @param format
   * @returns
   */
  static formatDate(
    date: Date | number,
    format = 'yyyy-MM-dd hh:mm:ss'
  ): string {
    if (!date) {
      return ''
    }

    if (typeof date === 'number' || typeof date === 'string') {
      date = new Date(date)
    }
    const o: Record<string, number> = {
      'y+': date.getFullYear(),
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
    }

    for (const k in o) {
      const reg = new RegExp(`(${k})`)
      const replaceVal = o[k] + ''
      format = format.replace(reg, (s) => {
        if (!s.startsWith('y') && replaceVal.length < s.length) {
          return this.addZero(+replaceVal)
        }

        return replaceVal + ''
      })
    }
    return format
  }

  //s是否在微信体系内
  static isWeChat(): Boolean {
    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.includes('micromessenger')
  }

  //是否是ios
  static isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
  //是否是移动的
  static isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )

  static getQueryString(keyName: any) {
    var url = window.location.href //获取url中"?"符后的字串
    // console.log(">>lhg>>",url)
    let params = url.split('?')[1]
    let paramsObj: any = {}
    if (params) {
      let paramsArr = params.split('&')
      for (let i = 0; i < paramsArr.length; i++) {
        let param = paramsArr[i].split('=')
        paramsObj[param[0]] = param[1]
      }
    }
    if (paramsObj && paramsObj[keyName]) {
      return paramsObj[keyName]
    }
    return ''
  }

  //打开小鸿页面
  static gotoXhWeb() {
    //wx.navigateBack({
    //     delta: 5,
    //   })
    window.wx.miniProgram.navigateBack({
      delta: 10,
    })
  }
  //打开自定义页面(目前注意是作文页面用，此页面应用与小程序页不需要获取openid等信息)
  static gotoWebview(url: string) {
    window.wx.miniProgram.navigateTo({
      url:
        '/pages/webview/webview?url=' + encodeURIComponent(JSON.stringify(url)),
    })
  }
  static shachPage() {
    // localStorage.removeItem('userToken');
    console.log(">>>>>?>chaoshi>>>>")
    Data.isShowLodeTip.value=true;
    // ElementTip.tipPage();
    // ElMessageBox.alert('登录超时，重新登录', '提示', {
    //   confirmButtonText: '确定',
    //   show-close:false
    //   callback: () => {
    //     const urlWithoutParams = window.location.href.split('?')[0]
    //     location.replace(urlWithoutParams)
    //   },
    // })
  }
  static wxLoade() {
    return new Promise((resolve, reject) => {
      let _code = Utill.getQueryString('code')
      try {
        if (_code) {

          
          HTTP.getToken(_code)
            .then((res: any) => {
              console.log('>>>_token》》', res)
              let _token = res.data
              localStorage.setItem('userToken', _token)
              Data.userToken.value = _token
              resolve(_token)
              // alert("shezhi"+ window.history.length)
            //   if (window.history && window.history.length > 0) {
            //     // 如果有历史记录，则清除
            //     window.history.go(window.history.length * -1);
            //     window.history.pushState({}, '', window.location.href);
            // }
              // window.history.back();
              // history.pushState({ page: 1 }, '', window.location.href);
              // getUserInfo(_token)
            })
            .catch((err: any) => {
              reject('登录失败')
              console.log(err)
            })
            
        } else {
          let url = encodeURIComponent(window.location.href)
          console.log(url)
         //https://ikeben.cn/api/login/baihong.php?redirect=https://ikeben.cn/chat/teachingAssistant/dist/
          // window.location.href='https://ikeben.cn/api/login/baihong.php?redirect='+ encodeURIComponent(url)
          // window.location.replace('https://ikeben.cn/api/login/baihong.php?redirect='+ encodeURIComponent(url));
          window.location.replace(
            'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3312282f3ed46af9&redirect_uri=' +
              url +
              '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
          )
        }
      } catch (error) {
        reject('登录失败')
      }
    })
  }
}

<script setup lang="ts">
import StreamMessageItem from '../components/massage/StreamMessageItem.vue'
import TextMessageItem from '../components/massage/TextMessageItem.vue'
import LoadingItem from '../components/loadingItem.vue'
import SendItem from '../components/SendItem.vue'
import GradeCard from '../components/massage/GradeCard.vue'
import { HTTP } from '../network/http'
import {
  ref,
  reactive,
  onMounted,
  nextTick,
  onActivated,
  onBeforeUnmount,
} from 'vue'
import type {
  messageType,
  cardType,
  messageEnum,
} from '../stores/typeInterface'
import { networkTip } from '../stores/StaticText'
import { ElementTip } from '../stores/ElementTip'
import { Data } from '../stores/data'
import EnterView from '../components/homeItem/EnterView.vue'
import { Utill } from '../stores/Utill'

var xiaoxiArr = ref<messageType[]>([]) //消息

let lodingShow = ref(false) //loding是否显示

let isSending = ref(false) //消息是否正在接收中
//下拉刷新
var scFra: HTMLElement | null = null //滚动区域

var es: EventSource
let timer: any //文字显示计时器
let _streamId: string = '' //stream信息容器dom的id
let alltext = '' //接收到的文字
let strforcode = '_' //显示的文字
let str_ = '' //比显示文字少了_
let isalltext = false //文字是否全部接收完
let isStartSteam = ref(true) //是否开始 stream

init()
//初始化卡片内容
function init() {
 
  getInitData();
  // Utill.getGongjuData()
}
//获取初始数据
function getInitData() {
  HTTP.getInitData()
    .then(
      (res) => {
        if(res.code&&res.code==200&&res.data){
          Data.initData.value=res.data;
          addMassage(res.data.content,res.data.type)  
        }
        console.log(">cs>>",res.data)
      
      }
    )
}
function reviseSubject(){
  if (isSending.value) {
    ElementTip.warningTip('请耐心等待助教把话说完')
    return
  }
  if(Data.initData.value){
    addMassage(Data.initData.value.content,Data.initData.value.type) 

  }else{
    getInitData();
  }
}
//向gpt发送文字
function sendQuestion(text: string) {
  if (isSending.value) {
    ElementTip.warningTip('请耐心等待助教把话说完')
    return
  }
  console.log("Data.subjectName.value>>>",Data.subjectName.value)
  if (Data.subjectName.value=='') {
    ElementTip.warningTip('请先设置学科哦')
    return
  }
  getDate(text, '')
}

onMounted(() => {
  console.log('>>>>>>>>>>ghuazaila哈哈哈哈哈啊哈哈哈哈', document.body)

  if (document.getElementById('massage-ctr')) {
    scFra = document.getElementById('massage-ctr')
  }
  document.addEventListener('visibilitychange', handleVisibilityChange, false)
})

  onBeforeUnmount(() => {
    console.log('>>组件卸载之前执行的函数；>')
    document.removeEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false
    )
  })
function handleVisibilityChange() {
  if (document.visibilityState == 'visible') {
    if (isalltext) {
      strforcode = alltext
      str_ = alltext
      //    backText.isCopy = true
    } else {
      str_ = alltext
      strforcode = str_ + '_'
    }
    console.log('<<<<<<啦啦啦啦看到了', alltext)
  } else {
    // console.log(strforcode,'<<<<啦啦ss啦啦消失了')
  }
}
//获取消息请求
async function getDate(
  text: string | any,
  id: string = '',
) {
  console.log('>>>>>33333>>>>', text)
  let _text = ''
  if (typeof text === 'string') {
    _text = text
  } else {
    _text = text.text
  }
  //添加用户发送的消息
  isSending.value = true
  if (id == '') {
    addMassage(text, 2)
    _text = _text.trim() //去掉首尾空格
    if (_text == '') {
      console.log('>>请输入需要问的问题>>')
      addMassage({text:networkTip.nullText})
      return
    }
    lodingShow.value = true
  }
  newCoursewareDetail(_text, id)
}
function newCoursewareDetail(
  text: string | any,
  id: string = '',
) {
  streaming(id,text)
  // HTTP.newCoursewareDetail(text)
  //   .then(
  //     async (data) => {
  //       if (data.code == 200) {
  //         let _data = data.data         
          

  //           console.log('>>>>stream流')
  //       } else if (data.code == 400) {
  //         ElementTip.warningTip(data.descb)
  //       }
  //     },
  //     (err) => {
  //       //助教发出错误提示
  //       addMassage({text:networkTip.errText}, 1, false,  id)
  //     }
  //   )
  //   .catch((e) => {
  //     //助教发出错误提示
  //     addMassage({text:networkTip.errText}, 1, false,  id)
  //   })
}



//获取stream数据
function streaming(id: string = '' ,text:string) {
  console.log('>>>>>>isSending.value', isSending.value)
  //如果已经发送过内容，并且正在回答中

  if (!isSending.value && id == '') {
    lodingShow.value = false
    return
  }
  let cuTes=encodeURIComponent(text)
  // console.log(">编码前>>",text)
  // console.log(">编码后>>",cuTes)
  //     dapi.php
  es = new EventSource(Data.streamUrl+"?token="+Data.userToken.value+"&content="+cuTes)
  console.log('>>>>>>EventSource')
  alltext = '' //接收到的文字
  strforcode = '_' //显示的文字
  str_ = '' //比显示文字少了_
  isalltext = false //是否全部接收完毕
  let isstarted = true //第一次成功返回
  let backText: messageType

  let isSwitch = id != '' && xiaoxiArr.value.find((item) => item.id == id)
  if (isSwitch) {
    _streamId = id
    backText = xiaoxiArr.value.find((item) => item.id == id)!
    backText.loadingType.push(1)
  } else {
    _streamId = 'mess-0-' + xiaoxiArr.value.length
    backText = reactive({
      text: ['_'],
      type: 4,
      isCopy: [false],
      id: _streamId,
      loadingType: [1],
    })
  }

  es.onerror = function (event) {
    console.error(xiaoxiArr.value.find((item) => item.id == backText.id),'>>>EventSource>error>>', event)
    closeStream(backText, 1)
    if (backText.text.length==0 ||  !xiaoxiArr.value.find((item) => item.id == backText.id)) {
      addMassage({text:networkTip.errText}, 1, false, _streamId)
    }
    return
  }

  es.onmessage = function (event) {
    // console.log()
    // let json = JSON.parse(event.data)
    if(event.data=='{"code":"401"}'){
      // ElementTip.errorTip('登录超时，重新登录')
      
      Utill.shachPage();
      return;
    }
    if (isstarted && alltext.length>0) {
      isstarted = false
      if (!isSwitch) {
        xiaoxiArr.value.push(backText)
        backText.type = 4
      }
      lodingShow.value = false
      isStartSteam.value = true
      // let i = 0;
      if (timer) {
        clearInterval(timer)
      }
      timer = setInterval(() => {
        let newalltext = alltext
        if (newalltext.split('\n\n').length == newalltext.split('\n').length) {
          newalltext = newalltext.replace(/\\n\\n/g, '\n')
        }
        if (str_.length < newalltext.length) {
          str_ += newalltext[str_.length]
          strforcode = str_ + '_'
          // console.log(">ss>>>",strforcode)
          if (str_.split('```').length % 2 == 0) strforcode += '\n```\n'
        } else {
          if (isalltext) {
            // console.log('>>>lhg346>stream返回完成>>', alltext)
            clearInterval(timer)
            strforcode = str_
            console.log(">>>lhg346>stream返回完成>>>",strforcode)
            ;(backText.isCopy as Array<boolean>)[backText.text.length - 1] =
              true
            backText.loadingType[backText.text.length - 1] = 2
            isSending.value = false
            isStartSteam.value = false
            
          }
        }
        ;(backText.text as Array<string>)[backText.text.length - 1] = strforcode
        // console.log(">33>>>>",backText)
        setScrollTop()
      }, 30)
    }
console.log(">event>>>",event.data);
    if (event.data == '[DONE]') {
      console.log('[DONE]>>stop>')
      isalltext = true
      es.close()
      return
    }
    var json = JSON.parse(event.data)
    if (json.choices[0] && json.choices[0].hasOwnProperty('finish_reason')) {
      if (json.choices[0].finish_reason == 'stop') {
        isalltext = true
        // backText!.isCopy = true
        console.log('finish_reason>>stop>')
        es.close()
        return
      }
    }
    // console.log(json.choices[0], json.choices[0].delta.hasOwnProperty('content'),'>>>4443333>>', alltext)
    if (
      json.choices[0] &&
      json.choices[0].delta &&
      json.choices[0].delta.hasOwnProperty('content')
    ) {
      let text = json.choices[0].delta.content
      if (alltext == '') {
        alltext = text.replace(/^\n+/, '') //去掉回复消息中偶尔开头就存在的连续换行符
      } else {
        alltext += text
      }
      alltext = removeTags(alltext); //去掉恢复中的p标签
      // (backText.text as Array<string>)[backText.text.length - 1] = alltext
      console.log(alltext.length,'>>>44433335555>>', alltext)
      // if(alltext){

      // }
    }
  }
}



//添加消息数据
function addMassage(
  text: any  = {text:networkTip.errText},
  type: messageEnum = 1,
  isCopy: boolean = false,
  id: string = '',
  loadingType: number[] = [3],
) {
  lodingShow.value = false
  if (type != 2 ) {
    isSending.value = false
    isStartSteam.value = false
  }

  if (id == '' && !xiaoxiArr.value.some((item) => item.id == id)) {
    console.log('>>>>>>lhg000', text)
    // let leng = xiaoxiArr.value.length;
    let _id = 'mess-' + type + '-' + xiaoxiArr.value.length
    let errorText: messageType = {
      text,
      type,
      isCopy,
      id: _id,
      loadingType,
    }
    xiaoxiArr.value.push(errorText)
  } else {
    console.log('>>>>>>lhg111')
    addPush(id, text)
  }
  setScrollTop()
}
function addPush(id: string, str: string = networkTip.errText) {
  let item = xiaoxiArr.value.find((item) => item.id == id)
  if (!item) {
    return
  }
  if (typeof item!.text === 'string') {
    let arr = []
    arr.push(item!.text)
    arr.push(str)
    item!.text = arr
    let arrIsCopy = []
    arrIsCopy.push(item!.isCopy as boolean)
    arrIsCopy.push(false)
    item!.isCopy = arrIsCopy
    // item.loadingType.push(1);
  } else {
    if (str == '_') {
      //新增
      console.log('>>>>>>lhg13333')
      ;(item!.text as Array<string>).push(str)
      ;(item!.isCopy as Array<Object>).push(false)
      //  item.loadingType.push(1);
    } else {
      //修改（请求错误的时候会走这里）
      console.log('>>>>>>lhg222')
      ;(item!.text as Array<string>)[item!.text.length - 1] = str
      ;(item!.isCopy as Array<Object>)[item!.text.length - 1] = true
      // (item!.isCopy as Array<Object>).push(true);
      item.loadingType[item.text.length - 1] = 3
    }
  }

  console.log('item.text>>>', item!.text)
}
function closeStream(back: messageType | undefined, frome: number = 0) {
  if (timer) {
    clearInterval(timer)
  }
  if (back) {
    
    let _text = (back.text as Array<string>)[back.text.length - 1]
      console.log(_text.length,'back>>>', _text)
    if (back && back.type == 4) {
      if ((_text as string).endsWith('_')) {
        console.log('back>>22>')
        ;(back.text as Array<string>)[back.text.length - 1] = _text.slice(0, -1)
          // if ((back.text as Array<string>)[back.text.length - 1] == '') {
        if (!(back.text as Array<string>)[back.text.length - 1] ) {
          console.log('back>>2233>')
          if (frome == 1) {
            console.log('back>>22444>')
            ;(back.text as Array<string>)[back.text.length - 1] =
              networkTip.errText
          } else {
            ;(back.text as Array<string>)[back.text.length - 1] = '已停止回答'
          }
        }
        console.log('backlhg>>>', back)
      }
      //这个从(_text as string).endsWith('_')里面移出来，是因为如果是代码的时候最后三个会是```导致结束的时候
      if (_text != '_' || back.text.length > 1) {
        ;(back.isCopy as Array<boolean>)[back.text.length - 1] = true
      }
      back.loadingType[back.text.length - 1] = 3
    }
  }
  _streamId = ''
  if (es) {
    es.close()
  }
  console.log('back33>>>', back)
  lodingShow.value = false
  isSending.value = false
  isStartSteam.value = false
}

function removeTags(str: string): string {
  const regex = /<\/?(p|br)>/g
  return str.replace(regex, '')
}

function setScrollTop() {
  // console.log(">setScrollTop>>>>")
  nextTick(() => {
    if (scFra != null) {
      scFra.scrollTop = 100000
    }
  })
}
// function lodeTipClick(){
//   localStorage.removeItem('userToken');
//   Data.isShowLodeTip.value=false;
//   const urlWithoutParams = window.location.href.split('?')[0]
//   location.replace(urlWithoutParams);
// }
function stopMassage() {
  let res = undefined
  if (_streamId) {
    res = xiaoxiArr.value.find((val) => {
      return val.id == _streamId
    })
  }
  console.log('>>>>res>', res)
  closeStream(res)
}

//点击重新回答逻辑
async function anginAnswer({
  id,
  problem,
}: {
  id: string
  problem: string | any
}) {
  if (isSending.value) {
    ElementTip.warningTip('请耐心等待助教把话说完')
    return
  }
  console.log('id, problem>>>', id, '>>>', problem)
  if (problem && id) {
    let item = xiaoxiArr.value.find((item) => item.id == id)
    if (item) {
      addPush(id, '_')
      getDate(problem, id)
    }
  }
}
function gradeChange(data:any){
  addMassage(data.content,data.type)   

}
//获取回答的问题
function getAnswerProblem(index: number) {
  let arr = xiaoxiArr.value
  for (let i = index; i >= 0; i--) {
    if (arr[i].type == 2) {
      console.log(' arr[i].text>>>>', arr[i].text)
      return arr[i].text
    }
  }
  return ''
}
</script>

<template>
  <div class="home-view">
    <div class="conter">
      <div class="pullToRefresh" id="massage-ctr">
        <!-- 新信息 -->
        <div id="new-ctr-math">
          <div
            v-for="(file, index) in xiaoxiArr"
            :key="file.id"
            class="tab"
            :id="file.id"
          >
            <SendItem
              :backText="(file.text as string)"
              v-if="file.type == 2"
            ></SendItem>
           
           
            <GradeCard
              :backText="(file.text as any)"
              v-else-if="file.type == 3"
              :isLast="index == xiaoxiArr.length - 1"
              @gradeChange="gradeChange"
            ></GradeCard>
            <StreamMessageItem
              :problem="getAnswerProblem(index)"             
              :isCopy="file.isCopy"
              :backText="(file.text as Array<String>)"
              @anginAnswer="anginAnswer"
              :messageKey="file.id"
              :isLast="index == xiaoxiArr.length - 1"
              :loadingType="file.loadingType"
              v-else-if="file.type==4"
            ></StreamMessageItem>
            <TextMessageItem
              :backText="file.text"
              v-else
            ></TextMessageItem>
          </div>
        </div>

        <!-- loading -->
        <div v-show="lodingShow">
          <LoadingItem class="loding" :is-show="lodingShow"></LoadingItem>
        </div>
      </div>
      <!-- </el-scrollbar> -->
    </div>
    <div class="down-ctr">
      <div class="quick-ctr">
        <div class="quickItem" @click="reviseSubject">
      <img class="quick-icon" src='../assets/book.svg' />
      <span>修改学科</span>
     </div>
      </div>
     
      <!-- 输入区域 -->
      <EnterView
        :isSending="isSending"
        :isStartSteam="isStartSteam"
        @stopMassage="stopMassage"
        @sendQuestion="sendQuestion"
      ></EnterView>
    </div>
  </div>
  <!-- <div v-show="Data.isShowLodeTip.value" class="zj-pre">
    <div class="lodeTip-ctr">
      <div class="lodeTip-text">
       登录超时，重新登录
    </div>
    <div class="lodeTip-btn" @click="lodeTipClick">
      确定
    </div>
    </div>
  </div> -->
</template>
<style scoped>
.lodeTip-btn{
  width: 0.8rem;
    line-height: 0.4rem;
    background: #59adec;
    border-radius: 0.1rem;
    text-align: center;
    color: #fff;
}
.lodeTip-text{
line-height: 1rem;
    text-align: center;
}
.lodeTip-ctr{
  width: 80%;
    /* height: 2rem; */
    background: #fff;
    border-radius: 0.1rem;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 0.1rem;
}
.quick-ctr{
  padding: 0 0.08rem;
  padding-top: 0.04rem;
}
.quick-icon {
  display: inline-block;
    max-width: 0.16rem;
    margin-right: 0.03rem;
    max-height: 0.16rem;
}
.quickItem {
  width: 1rem;
    height: 0.32rem;
    border-radius: 1.62rem;
    opacity: 1;
    background: #ffffff;
    font-weight: 300;
    /* line-height: 0.3rem; */
    /* flex: 3; */
    text-align: center;
    /* margin-right: 0.08rem; */
    display: flex;
    /* padding: 0 0.13rem; */
    cursor: pointer;
    /* min-width: 1.04rem; */
    white-space: nowrap;
    justify-content: center;
    align-items: center;
}
.documents-item-ctr {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0.08rem;
  padding-top: 0.05rem;
  gap: 0.08rem;
  overflow-x: scroll;
}

.lw-tip-ctr {
  position: absolute;
  z-index: 1;
  top: -0.35rem;
  color: #fff;
}
.tip-body {
  border-radius: 8px;
  opacity: 1;
  background: #3b3b3b;
  padding: 0.08rem;
  display: flex;
  align-items: center;
}
.tip-body::before {
  content: ' ';
  position: absolute;
  border: 0.09rem solid transparent;
  width: 0;
  height: 0;
  border-bottom-color: #3b3b3b;
  left: 0.53rem;
  bottom: -0.16rem;
  rotate: 180;
  transform: rotate(180deg);
}
.fengexian {
  width: 1px;
  /* height: 60%; */
  background: #ccc;
  /* margin-top: 20%; */
  height: 0.2rem;
}
.lw-ctr {
  /* height: 100%; */
  /* padding: 0 0.1rem; */
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 0.09rem;
  gap: 0.1rem;
  padding-left: 0.1rem;
}
.lw-ico {
  width: 0.18rem;
  /* height: 100%;
    display: flex; */
  /* padding: 0 0.1rem; */
  height: 0.18rem;
}
.el-input {
  flex: 1;
}
.residue-num {
  margin-bottom: 0.12rem;
  text-align: center;
  color: #86909c;
  font-size: 0.12rem;
}
.residue-num span {
  color: #59adec;
}

.media-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 222;
  background: #000;
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.16rem; */
}

.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 8s
    forwards;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 8s forwards;
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(36px);
    transform: translateX(36px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(36px);
    transform: translateX(36px);
  }
}

.jiantou-ico {
  transform: rotate(180deg);
  width: 0.04rem;
  margin-left: 0.12rem;
}
.zj-pre {
  width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: #00000054;
    display: flex;
}
.my-information-btn span {
  width: 0.32rem;
  text-align: left;
}
.my-information-btn {
  position: absolute;
  top: -0.44rem;
  right: 0;
  background: rgb(255, 255, 255, 0.6);
  border-radius: 30px 0px 0 30px;
  line-height: 0.14rem;
  text-align: center;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 0.1rem 0.02rem 0.1rem 0.12rem;
  z-index: 1;
  display: flex;
  gap: 0.06rem;
  align-items: flex-end;
}
.iframe-ctr {
  height: 100%;
}
.iframe {
  width: 100%;
  height: 99%;
}
.close-iframe span {
  /* position: relative;
    top: 2px;
    font-size: 0.2rem; */
  margin-left: 0.08rem;
}
.close-iframe {
  position: absolute;
  left: 0;
  flex: 0 0 auto;
  background: #ffffff;
  box-sizing: border-box;
  /* border: 1px solid #E5E6EB; */
  color: #1d2129;
  font-size: 0.14rem;
  width: 0.7rem;
  border-radius: 0 1rem 1rem 0rem;
  line-height: 0.36rem;
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  /* height: 0.36rem; */
  top: 0.6rem;
  text-align: left;
}
.img-cropper-ctr {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  /* padding: 0.1rem; */
  background: #f5f6fa;
}

:deep(.el-textarea.is-disabled) .el-textarea__inner {
  background-color: #fff !important;
}

:deep(.el-textarea__inner):focus {
  /* box-shadow: 0 0 0 1px #f19938 inset; */
  box-shadow: none;
}
:deep(.el-textarea__inner) {
  background-color: #fff;
  border: 0;
  border-radius: 0.2rem;
  caret-color: #59aded;
  line-height: 0.22rem;
  max-height: 1.2rem;
  overflow: hidden;
  transition: border-color 0.15s ease-in-out;
  word-break: break-all;
  /* border: 0; */
  resize: none;

  border-radius: 10px;
  box-sizing: border-box;
  line-height: 1.5;
  min-height: 0.24rem;
  padding: 8px 12px;
  width: 100%;
  font-size: 0.14rem;
  box-shadow: none;
  color: #1e1e1e;
}

.home-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.conter {
  width: 100%;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  position: relative;
  padding: 0rem 0.08rem;
  min-height: 0;
  padding-top: 0.16rem;
  /* height: calc(100% - 140px); */
}
.pullToRefresh {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-y: scroll;
  position: static;
  z-index: 1;
  min-height: 0;
}

.pullToRefresh > * {
  transform: translateZ(0px);
  -webkit-transform: translateZ(0px);
}
.down-ctr {
  width: 100%;
  background: #f5f6fa;
  position: relative;

  /* height: 140px; */
}
.composer {
  -ms-flex-align: end;
  align-items: flex-end;
  display: -ms-flexbox;
  display: flex;
  padding: 0.08rem;
  position: relative;
}
.composer-inputWrap {
  flex: 1;
  position: relative;
  min-width: 0;
  display: flex;
  flex-wrap: nowrap;
  background: #fff;
  border-radius: 0.1rem;
  overflow: hidden;
  /* height: 37px; */
  align-items: flex-start;
}
.Composer-actions {
  margin-left: 8px;
  flex-shrink: 0;
  width: 0.35rem;
  height: 0.35rem;
}

.Composer-actions .IconBtn {
  background: #59adec;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 0;
  cursor: pointer;
  padding: 0;
}

.Icon {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
/* .IconBtn:hover {
    background: #fff
	}
.IconBtn:hover img{
    transform: translateX(30px);
  filter: drop-shadow(#F19938 -30px 0px 0px);
		
	} */
</style>

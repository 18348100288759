<script setup lang="ts">
import { ref, watch } from 'vue'
import { MdPreview, MdCatalog } from 'md-editor-v3'
import { Data } from '../../stores/data'
import { HTTP } from '../../network/http'
// preview.css相比style.css少了编辑器那部分样式
import 'md-editor-v3/lib/preview.css'
import { ElementTip } from '@/stores/ElementTip'
const $emit = defineEmits(['gradeChange'])
var def = defineProps({
  backText: {
    type: Object,
    default: {},
  },
  isLast: {
    type: Boolean,
    default: false,
  },
})

let selectedIndex = ref('')
let isClicking =false;
function gradeChange(name: string) {

  if(isClicking){
    ElementTip.errorTip('学科设置中，请稍候')
    return
  }
  isClicking=true;
  HTTP.setSubjectName(name).then((res) => {
    console.log(">>>nme>>",res)
    selectedIndex.value = name
    Data.subjectName.value=name
    let _text='告诉我，你在哪一页第几题遇到困难了，我会耐心为您解答～'
    let _example='例：第2页第1题或p2-1'
    if(res.data&&res.data?.content){
      if(res.data?.content.text){
        _text=res.data?.content.text;
      }
      if(res.data?.content.example){
        _example=res.data?.content.example;
      }

    }
    $emit('gradeChange', {
    type: 1,
    content: {
      text: _text,
      example: _example,
    }
  })
  isClicking=false
  }).catch((e)=>{
    isClicking=false
  })
  

 
  // HTTP.getInitData()
  // .then(
  //   (data) => {
  //     console.log(">cs>>",data)
  //     let leng = data.length
  //     if (leng > 0) {
  //       for (let i = 0; i < leng; i++) {
  //         addMassage(data[i].content,data[i].type)
  //       }
  //     } else {
  //       addMassage() //助教发出错误提示
  //     }
  //   },
  //   (err) => {
  //     addMassage() //助教发出错误提示
  //   }
  // )
  // .catch((e) => {
  //   addMassage() //助教发出错误提示
  // })
}
</script>

<template>
  <div class="mess">
    <div class="mess-ctr">
      <div class="avatar">
        <img
          class="rounded-circle"
          src="https://static.yipigai.cn/avatar84.png"
          alt="avatar"
        />
      </div>
      <div class="cro">
        <div class="cro_left_top"></div>
      </div>
      <div class="text-ctr">
        <MdPreview
          class="backText"
          :no-katex="false"
          v-model="(backText.text as string)"
        ></MdPreview>
        <div v-if="backText?.example" class="text-title">
          {{ backText?.example }}
        </div>
      </div>
    </div>
    <div class="grade-ctr" v-if="backText?.btns">
      <div class="grade-item-ctr">
        <div
          v-for="item in backText?.btns"
          :key="item"
          :class="[
            'grade-item',
            { active: selectedIndex === item, disabled: selectedIndex !== '' || !isLast },
          ]"
          @click="gradeChange(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* .grade-lx{
    margin-bottom: 0.16rem;
} */
.grade-title {
  margin-bottom: 0.08rem;
}
.join-mode-tip {
  width: 100%;
  /* text-align: center;
    font-size: 12px;
    color: #666;
    margin-bottom: 0.16rem; */
}
.up-img {
  margin-top: 0.16rem;
}
.mess .grade-item.active {
  border: 1px solid #59adec;
  background: rgba(89, 173, 236, 0.1);
  color: #59adec;
  /* opacity: 1; */
}
.grade-item.disabled {
  pointer-events: none;
  color: #c9cdd4;
  /* opacity: 0.5; */
}
.grade-item {
  min-width: 0.58rem;
  border-radius: 2.5rem;
  text-align: center;
  line-height: 0.26rem;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  color: #4e5969;
  border: 1px solid #e5e6eb;
  /* margin-top: 0.08rem; */
  padding: 0 0.06rem;
}
.grade-item-ctr {
  display: flex;
  gap: 0.04rem;
  flex-wrap: wrap;
}
.grade-ctr {
  font-weight: normal;
  width: 2.68rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.16rem;
  margin-top: 0.08rem;
  margin-left: 0.41rem;
  border-radius: 20px;
  background: #ffffff;
  padding: 0.12rem;
  flex-direction: column;
}
.mess {
  padding: 0 0rem 0.16rem 0;
  max-width: 95%;
  -webkit-animation: msgLeft 0.5s ease-in-out;
  animation: msgLeft 0.5s ease-in-out;
}

.mess-ctr {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.rounded-circle {
  display: block;
}

.avatar {
  min-width: 0.35rem;
  min-height: 0.35rem;
  border-radius: 0.3rem;
  overflow: hidden;

  height: 0.35rem;
  width: 0.35rem;
  flex-shrink: 0;
}

.text-ctr {
  white-space: pre-wrap;
  background-color: #fff;
  padding: 0.1rem 0.12rem;
  /* max-width: calc(100% - 0.35rem); */
  border-radius: 0 20px 20px 20px;
  font-weight: normal;
  line-height: 0.24rem;
  box-sizing: border-box;
  /* margin-left: 0.06rem; */
  min-width: 0;
  overflow: hidden;
}
</style>

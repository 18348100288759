<script setup lang="ts">
import HomeView from '../src/views/HomeView.vue'
import { ref , onMounted } from 'vue'
import { HTTP } from '../src/network/http'
import { Data } from '../src/stores/data'
import { Utill } from '@/stores/Utill'
import {ElementTip} from './stores/ElementTip'
let initDataCom = ref(0);

onMounted(() => {

  // let loadTime=localStorage.getItem('loadTime');
  // 获取当前时间戳
const currentTime = new Date().getTime();

// 获取上次保存的时间戳
const lastTime = localStorage.getItem('lastVisitTime');

// 如果是首次访问或者距离上次访问时间超过 24 小时，则保存当前时间戳
if (!lastTime || (currentTime - parseInt(lastTime)) >= ( 24 * 60 * 60 * 1000)) {
  localStorage.removeItem('userToken');
  console.log('距离上次访问超过24小时，已更新访问时间。');
} else {
    console.log('距离上次访问未超过24小时。');
}
localStorage.setItem('lastVisitTime', currentTime.toString());

  let userToken=localStorage.getItem('userToken');
   // alert(userToken)
  // userToken='2127c840-aced-412b-8fef-9cf9e46170d2'
  if(userToken&&userToken!=null){
    console.log(">已有>>")
    Data.userToken.value = userToken;
    initDataCom.value=1;
    getUserInfo();

  }else{
    console.log(">公众号授权>>")
    //公众号授权
    loadData();
  }

})


// var isPageHide = false;
// window.addEventListener('pageshow', function () {
//   alert("isPageHide>>"+isPageHide)
// if (isPageHide) {
//   // console.log(isPageHide,">>>>")
// window.location.reload();
// }
// });
// window.addEventListener('pagehide', function () {
// isPageHide = true;
// });

// window.addEventListener('popstate', () => {
//     console.log('关闭微信页面')
//     window.WeixinJSBridge.invoke('closeWindow')
// })

function loadData() {
  Utill.wxLoade()
    .then((res) => {
      initDataCom.value = 1;
      getUserInfo();
    })
    .catch(() => {
      initDataCom.value = 2;
      // 在捕获到错误时刷新页面，并在 10 秒后执行
     Utill.shachPage()
    });
}

function getUserInfo(){
    HTTP.getUserInfo().then((res:any)=>{
      Data.userInfo.value=res.data
      console.log(">>>用户信息》》",res)
    }).catch((err:any)=>{
      console.log(err)
    })
  console.log(Data.userInfo.value)
}
function lodeTipClick(){
  localStorage.removeItem('userToken');
  Data.isShowLodeTip.value=false;
  const urlWithoutParams = window.location.href.split('?')[0]
  location.replace(urlWithoutParams);
}
</script>

<template>
 <div class="root" v-if="initDataCom==1">

    <div class="home">
       <HomeView></HomeView>      
    </div>  
    
  </div>
  <div v-else class="loadingCLass">
    <img src="./assets/hexagon1.svg">
    <div>{{initDataCom==2?'登录失败':'登录中……'}}</div>
  </div>
  <div v-show="Data.isShowLodeTip.value" class="zj-pre">
    <div class="lodeTip-ctr">
      <div class="lodeTip-text">
       登录超时，重新登录
    </div>
    <div class="lodeTip-btn" @click="lodeTipClick">
      确定
    </div>
    </div>
    
    <!-- <GongjuDrawer @clickCard="clickCard"></GongjuDrawer> -->
  </div>
</template>

<style scoped>
.lodeTip-btn{
  width: 0.8rem;
    line-height: 0.4rem;
    background: #59adec;
    border-radius: 0.1rem;
    text-align: center;
    color: #fff;
}
.lodeTip-text{
line-height: 1rem;
    text-align: center;
}
.lodeTip-ctr{
  width: 80%;
    /* height: 2rem; */
    background: #fff;
    border-radius: 0.1rem;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 0.1rem;
}
.zj-pre {
  width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: #00000054;
    display: flex;
    font-size: 0.14rem;
}
.loadingCLass img{
    margin-right: 0.06rem;
    -webkit-animation: preloader-spin 1s steps(12, end) infinite;
    animation: preloader-spin 1s steps(12, end) infinite;
    /* transform: translateZ(0); */
}
  @keyframes preloader-spin  {
        from {
           transform: rotate(0); 
        }
        to {
            transform: rotate(360deg)
        }
    }
    @-webkit-keyframes preloader-spin  {
        from {
           transform: rotate(0); 
        }
        to {
            transform: rotate(360deg)
        }
    }
.loadingCLass{
    width: 100%;
    height: 100%;
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 0.14rem;
}
.root{
  height: 100vh;
  width: 100%;
  background: #f5f6fa;
  max-width: 10rem;
  margin: auto;
  position: relative;

  
}
.home{
font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
/* font-family: "my-Regular",sans-serif; */
  text-align: left;
  color: #1e1e1e;
  font-size: 0.14rem;
  background: #f5f6fa;
  height: 100vh;
  max-width: 10rem;
  overflow: hidden;
  margin: 0 auto;
  /* background-color: #fff; */
  /* 兼容 苹果手机 */
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  box-sizing: border-box;
  /* padding: 0rem 0.08rem 0.04rem; */
 /* position: relative; */
}
</style>
<style>
/* 公式超出出现滚动条 */
.katex-display { overflow: auto hidden }

/* markdown css*/
.default-theme p{
        padding: 0.05rem 0 !important;

}
.md-editor-preview{
    font-size: 0.14rem !important

}
.default-theme ul {
    padding-left: 0 !important;
}
.default-theme table thead{
       background: #abcee826 !important;
}
.md-editor-preview-wrapper{
    padding:0 !important;
}
.md-editor{
    --md-color: #1e1e1e !important;
}
.text-ctr .default-theme h1, .default-theme h2, .default-theme h3, .default-theme h4, .default-theme h5, .default-theme h6{
  margin: 0 !important;
}
</style>

import type {
  imgDataType,compositionType
} from './typeInterface'
import { reactive, ref } from 'vue'

export class Data {

    static streamUrl = import.meta.env.VITE_BASE_URL + import.meta.env.VITE_XIAOXI_URL;//stream流地址
    static userInfo=ref<any>();
    static userToken=ref('');
    static subjectName=ref('')
    static initData=ref<any>()
    static isShowLodeTip=ref(false)
    
}

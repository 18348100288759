<script setup lang="ts">
import useClipboard from 'vue-clipboard3'
import { ref, nextTick, watch } from 'vue'
import { ElementTip } from '../../stores/ElementTip'
import { MdPreview, MdCatalog } from 'md-editor-v3'
// preview.css相比style.css少了编辑器那部分样式
import 'md-editor-v3/lib/preview.css'

const scrollElement = document.documentElement

const $emit = defineEmits([
  'anginAnswer',
])

let strIndex = ref(1)
//父级入参
let def = defineProps({
  backText: {
    type: [String, Array],
    default: '',
  },
  isCopy: {
    //可以copy证明是否已经显示完成
    type: [Boolean, Array],
    default: false,
  },

  problem: {
    type: [String, Object],
    default: '',
  },
  messageKey: {
    type: String,
    default: '',
  },
  isLast: {
    type: Boolean,
    default: false,
  },
  loadingType: {
    type: Array,
    default: null,
  },
})

// 点击复制
function touchCopy() {
  // 调用
  copy(getCurrText())
}
function getCurrText() {
  if (typeof def.backText === 'string') {
    return def.backText
  } else {
    return (def.backText as Array<string>)[strIndex.value - 1]
  }
}
// 使用插件
const { toClipboard } = useClipboard()
const copy = async (msg: string) => {
  try {
    // 复制
    await toClipboard(msg)
    ElementTip.successTip('复制成功')
    // 复制成功
  } catch (e) {
    // 复制失败
    ElementTip.errorTip('复制失败')
  }
}

function anginAnswer() {
  $emit('anginAnswer', { id: def.messageKey, problem: def.problem })
}

watch(
  () => def.backText.length,
  (newValue, oldValue) => {
    // console.log(def.backText, `imgSrc changed from ${oldValue} to ${newValue}`);
    strIndex.value = def.backText.length
  },
  //深度监听
  { deep: true }
)

function changeStrIndex(num: number) {
  let newNum = strIndex.value + num
  if (newNum < 1) {
    newNum = 1
  } else if (newNum > def.backText.length) {
    newNum = def.backText.length
  }
  // console.log(def.backText.length,">>>>>>", newNum)
  strIndex.value = newNum
}

function isShowCopy() {
  if (typeof def.isCopy === 'boolean') {
    return def.isCopy
  } else {
    return def.isCopy[strIndex.value - 1]
  }
}
//是否显示重新回答
function isShowReanswer() {
  if (def.isLast) {
    // if (def.problem && typeof def.problem == 'object' && def.problem.wjId) {
    //      return false
    // }
    return true
  }
  return false
}
</script>

<template>
  <div class="mess">
    <div class="mess-ctr">
      <div class="avatar">
        <img
          class="rounded-circle"
          src="https://static.yipigai.cn/avatar84.png"
          alt="avatar"
        />
      </div>
      <div class="cro">
        <div class="cro_left_top"></div>
      </div>
      <div class="text-ctr">
       
        <!-- 这里放置你需要生成图片的内容 -->

        <!-- isHtml 是因为嵌入的返回时代样式的  chatgpt不用 v-html的形式接受，是因为如果返回的带html会被解析为标签显示有问题-->
        <div v-if="typeof backText === 'string'">
          <!--  :previewTheme="'vuepress'"         -->
          <MdPreview
            class="backText"
            :no-katex="false"
            v-model="(backText as string)"
          ></MdPreview>
          <div class="tip-text" v-if="isShowCopy()">*有不明白的地方，还可向我继续提问哟</div>
        </div>
        <div v-else>
          <div>
            <MdPreview
              :no-katex="false"
              class="backText"
              v-model="(backText[strIndex - 1] as string)"
            ></MdPreview>
            <MdCatalog :scrollElement="scrollElement" />
            <div class="tip-text" v-if="isShowCopy()">*有不明白的地方，还可向我继续提问哟</div>
          </div>

          <div class="switch-ctr" v-if="backText.length > 1">
            <div
              class="switch-btn"
              :class="strIndex == 1 ? 'disabled' : ''"
              @click="changeStrIndex(-1)"
            >
              <img
                class="jiantou-ico left-jiantou"
                src="../../assets/jiantou1.svg"
                alt="avatar"
              />
              <!-- ‹ -->
            </div>
            <div>{{ strIndex }}/{{ backText.length }}</div>
            <div
              class="switch-btn"
              @click="changeStrIndex(1)"
              :class="strIndex == backText.length ? 'disabled' : ''"
            >
              <img
                class="jiantou-ico"
                src="../../assets/jiantou1.svg"
                alt="avatar"
              />
              <!-- › -->
            </div>
          </div>
        </div>

        <div class="copyctr" id="copyctr-id" v-if="isShowCopy()">
          <div class="copyBtn" @click="anginAnswer" v-if="isShowReanswer()">
            <img src="../../assets/chongshi.svg" />
            重新回答
          </div>
          <div class="copyBtn" @click="touchCopy">
            <img src="../../assets/copy.svg" />
            复制
          </div>
        </div>
      </div>
    </div>
    <div
      class="answer-loading-ctr"
      :class="strIndex == loadingType.length ? 'show-opacity' : 'hide-opacity'"
      v-if="loadingType && loadingType.length > 0"
    >
      <div
        class="xh-answer-over"
        v-if="loadingType[loadingType.length - 1] == 2"
      >
        <img src="../../assets/answerOver.svg" />
        <div>回答完成</div>
      </div>
      <div
        class="xh-answer-loading"
        v-else-if="loadingType[loadingType.length - 1] == 1"
      >
        <div>正在回答中</div>
        <div class="xh-loading-move-dian">
          <span class="dot1 loading-dian"></span>
          <span class="dot2 loading-dian"></span>
          <span class="dot3 loading-dian"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
 .tip-text{
  background: rgba(201, 205, 212, 0.2);
    border-radius: 8px;
    line-height: 0.3rem;
    color: #4E5969;
    text-align: center;
}
.text-title {
  border-radius: 8px;
  opacity: 1;
  background: #f7f8fa;
  line-height: 0.4rem;
  padding-left: 0.1rem;
  color: #59adec;
  font-weight: 500;
  white-space: nowrap;
}
.similar-ctr {
  border-radius: 16px;
  opacity: 1;

  background: #ffffff;
  padding: 0.12rem;
  margin-top: 0.08rem;
}
.jiantou-ctr {
  width: 0.2rem;
  height: 0.2rem;
  background: #e5e6eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-text {
  width: 76%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-item {
  display: flex;
  border-radius: 8px;
  background: #f7f8fa;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.04rem;
  cursor: pointer;
  line-height: 0.42rem;
}
.answer-loading-ctr {
  font-size: 0.12rem;
  position: absolute;
}
.show-opacity {
  opacity: 1;
}
.hide-opacity {
  opacity: 0;
}
/* .go-on-ico{

} */
.xh-up-move {
  -webkit-animation: slide-up 0.6s ease 0.7s forwards;
  animation: slide-up 0.6s ease 0.7s forwards;
  opacity: 0;
  width: calc(100% - 0.72rem);
  margin-left: 0.41rem;
}
@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(10x);
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-up {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.xh-answer-over {
  margin-left: 0.41rem;
  margin-top: 0.04rem;
  display: flex;
  -webkit-animation: fadeInAndOut 0.4s ease 0.4s forwards;
  animation: fadeInAndOut 0.4s ease 0.4s forwards;
}
@keyframes fadeInAndOut {
  0% {
    opacity: 1; /* 开始时透明度为 0 */
  }
  100% {
    opacity: 0; /* 结束时透明度为 0，消失状态 */
    display: none; /* 可选：在动画结束后隐藏 div */
  }
}
@-webkit-keyframes fadeInAndOut {
  0% {
    opacity: 1; /* 开始时透明度为 0 */
  }
  100% {
    opacity: 0; /* 结束时透明度为 0，消失状态 */
    display: none; /* 可选：在动画结束后隐藏 div */
  }
}
.xh-answer-loading {
  margin-left: 0.41rem;
  margin-top: 0.04rem;
  display: flex;
  align-items: baseline;
}
.downTipBtn {
  line-height: 0.24rem;
  border-radius: 0.8rem;
  opacity: 1;
  background: #e5e6eb;
  text-align: center;
  font-size: 0.12rem;
  color: #4e5969;
  margin-top: 0.08rem;
  display: flex;
  /* align-items: center; */
  flex-wrap: nowrap;
  justify-content: center;
}
@keyframes opacityAnimation {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.cs-move {
  animation: opacityAnimation 0.6s cubic-bezier(1, -0.09, 0, 1.12) infinite;
}
.left-jiantou {
  transform: rotate(180deg);
}
.jiantou-ico {
  /* transform: rotate(180deg); */
  width: 0.04rem;
  /* margin-left: 0.12rem; */
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.switch-ctr {
  display: flex;
  font-size: 0.12rem;
  gap: 0.04rem;
  margin-top: 0.1rem;
  line-height: 0.14rem;
  align-items: flex-start;
  margin-bottom: 0.08rem;
}
.switch-btn {
  /* font-size: 0.18rem;
    text-align: left;
    cursor: pointer; */
  cursor: pointer;
  width: 0.14rem;
  height: 0.14rem;
  display: flex;
  justify-content: center;
}
:deep(.el-textarea .el-input__count) {
  bottom: -18px;
  right: 0px;
}
.backBtn {
  color: #59adec;
  text-align: center;
  width: 100%;
  line-height: 0.36rem;
  font-size: 0.14rem;
  cursor: pointer;
}
.inputCon {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  margin-top: 9px;
}
.text-ctr.feedbackList {
  /* max-width: 5rem; */
  /* min-width: 260Px; */
  /* width: calc(100% - var(--rate-width) - 16Px); */
  width: 3rem;
  margin-left: 0.41rem;
  margin-top: 0.1rem;
  padding: 0;
}
.backListItem {
  margin-top: 9px;
  padding: 4px 12px;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.backListItem.backListItemSelend {
  color: #59adec;
  border: 1px solid #59adec;
}
.seceletList {
  width: 100%;
  box-sizing: border-box;
  /* height: 100%; */
  padding: 0.08rem 0.16rem 0.3rem 0.16rem;
  /* border-top: 1px solid #F2F2F2; */
  border-bottom: 1px solid #f2f2f2;
}
.feedbackList .title {
  font-size: 0.14rem;
  font-weight: 600;
}

.feedback .btn.goodSelecten {
  background: #59adec;
}
.feedback {
  display: flex;
  flex-direction: column-reverse;
  margin-left: 0.04rem;
}
.feedback .btn {
  background-color: #fff;
  padding: 0.06rem;
  border-radius: 50%;
  margin-top: 0.04rem;
  cursor: pointer;
  overflow: hidden;
  min-height: 0;
}
.feedback .btn:last-child {
  margin-top: 0;
}

.feedback .btn img {
  display: block;
  height: 14px;
  width: 14px;
  min-width: 14px;
}
.mess {
  padding: 0 0rem 0.16rem 0;
  max-width: 95%;
  -webkit-animation: msgLeft 0.5s ease-in-out;
  animation: msgLeft 0.5s ease-in-out;
}
.copyctr {
  display: flex;
  justify-content: flex-end;
  /* padding-bottom: 0.06rem; */
  border-top: 1px solid #f2f2f2;
  padding-top: 0.1rem;
  margin-top: 0.02rem;
}
.copyBtn {
  cursor: pointer;
  background: #fff;
  /* box-shadow: 0px 2px 4px 0px rgba(226, 95, 9, 0.2); */
  /* width: 0.84rem; */
  /* box-sizing: border-box; */
  padding: 0.06rem 0.12rem;
  color: #000;
  /* text-align: center; */
  /* height: 0.14rem; */
  font-size: 0.12rem;
  margin-left: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 1rem;
  line-height: 0.12rem;
  border: 1px solid #f2f2f2;
  /* width: 0.72rem; */
  box-sizing: border-box;
}
.copyBtn img {
  width: 0.12rem;
  margin-right: 0.02rem;
}
.loadingCLass {
  opacity: 0.7;
  pointer-events: none;
  transform: perspective(1000);
}
.isButtonDisabledClass {
  opacity: 0.7;
  pointer-events: none;
}
.loadingCLass img {
  -webkit-animation: preloader-spin 1s steps(12, end) infinite;
  animation: preloader-spin 1s steps(12, end) infinite;
  /* transform: translateZ(0); */
}
@keyframes preloader-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes preloader-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.mess-ctr {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.rounded-circle {
  display: block;
}

.avatar {
  min-width: 0.35rem;
  min-height: 0.35rem;
  border-radius: 0.3rem;
  overflow: hidden;

  height: 0.35rem;
  width: 0.35rem;
  flex-shrink: 0;
}
.text-ctr {
  /* white-space: pre-wrap; */
  background-color: #fff;
  padding: 0.1rem 0.12rem;
  /* max-width: calc(100% - 0.35rem); */
  border-radius: 0 20px 20px 20px;
  font-weight: normal;
  /* line-height: 0.24rem; */
  box-sizing: border-box;
  /* margin-left: 0.06rem; */
  min-width: 0;
  overflow: hidden;
}
</style>
<script setup lang="ts">
</script>

<template>
    <div class="mess">
        <div class="mess-ctr">
            <div class="text-ctr" >
                <div class="dot1"></div>
                <div class="dot2"></div>
                <div class="dot3"></div>
                <div class="dot4"></div>             
            </div> 
              
        </div>
       <div class="loading-text">
          正在思考中
        </div>      
    </div>   
</template>

<style scoped>
.loading-text{
        margin-left: 0.08rem;
        margin-top: 0.04rem;
    color: #4E5969;
    font-size: 0.12rem;
}
.mess{  
    padding: 0 1rem 0.16rem 0;
}
.mess-ctr{
    display: flex;   
    width: 100%;
    position: relative;
}

.text-ctr{
    white-space: pre-wrap;
    background-color: #fff;
    padding: 0.06rem 0.12rem;
    max-width: calc(100% - 0.35rem);
    border-radius: 4px 20px 20px 20px;
    font-weight: normal;
    line-height: 0.24rem;
    box-sizing: border-box;
    margin-left: 0.06rem;
}
.text-ctr div {
    /* -webkit-animation: typing-dot .9s linear infinite;
    animation: typing-dot .9s linear infinite; */
     -webkit-animation: xh-dian-show-xiaosi 1s linear infinite;
    animation: xh-dian-show-xiaosi 1s linear infinite;
    background: #59ADEC;
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-left: 5px;
    width: 6px;
}
.text-ctr .dot1 {           
    /* margin: 0;
    opacity: .9; */
     -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.text-ctr .dot2 {
    /* -webkit-animation-delay: .225s;
    animation-delay: .225s;
    opacity: .3; */
     -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.text-ctr .dot3 {
    /* -webkit-animation-delay: .45s;
    animation-delay: .45s;
    opacity: .6; */
     -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.text-ctr .dot4 {
    /* -webkit-animation-delay: .45s;
    animation-delay: .45s;
    opacity: .6; */
     -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.text-ctr .dot5 {
    /* -webkit-animation-delay: .45s;
    animation-delay: .45s;
    opacity: .6; */
     -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@keyframes typing-dot {
0% {
	-webkit-transform:translateY(0);
	transform:translateY(0)
 }
25% {
	-webkit-transform:translateY(6px);
	transform:translateY(6px)
}
50% {
	-webkit-transform:translateY(0);
	transform:translateY(0)
}
75% {
	-webkit-transform:translateY(-6px);
	transform:translateY(-6px)
}
to {
	-webkit-transform:translateY(0);
	transform:translateY(0)
}
}

</style>

!(function (doc, win) {
  //   const isMobile = () => {
  //     const flag = navigator.userAgent.match(
  //       /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  //     )
  //     return flag ? true : false
  //   }
  //   if (isMobile()) {
  var docEl = doc.documentElement,
    // 手机旋转事件,大部分手机浏览器都支持 onorientationchange 如果不支持，可以使用原始的 resize
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      //clientWidth: 获取对象可见内容的宽度，不包括滚动条，不包括边框
      var clientWidth = docEl.clientWidth
      if (!clientWidth) return
      // 当前页面宽度相对于 375 宽的缩放比例，可根据自己需要修改。
      // const scale = document .documentElement.clientWidth / 375
      // // 设置页面根节点字体大小 最高为两倍图 即设计稿为750
      // document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'

      //判断是否支持监听事件 ，不支持则停止dd
      if (clientWidth < 375) {
        docEl.style.fontSize = 100 * (clientWidth / 375) + 'px'
      } else {
        docEl.style.fontSize = '100px'
      }
    }
  //判断是否支持监听事件 ，不支持则停止
  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
  //   }
})(document, window)
